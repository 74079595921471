import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

class Contact extends Component {
  render() {
    return (
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <section className="my-5">
          <h2 className="h1-responsive font-weight-bold text-center my-5" style={{ color: "rgb(102, 8, 8)", fontSize: "3rem", textDecoration:"underline" }}>
            Contact us
          </h2>
          <Container fluid>
            <Row>
              <Col>
                <address
                  style={{
                    color: "rgb(102, 8, 8)",
                    fontWeight: "bold",
                    fontSize: "1rem"
                  }}
                >
                  <strong style={{ color: "rgb(102, 8, 8)", fontSize: "3rem" }}>
                    The Browz Threading
                  </strong>
                  <br />
                  1923 Massachusetts Ave
                  <br />
                  B, Cambridge, MA 01970
                  <br />
                  <abbr title="Phone">Phone:</abbr> (857) 770-8825 <br />
                  <abbr title="Phone">Phone:</abbr> (617) 945-9214
                </address>

                <address>
                  <strong>Isha Ranjit</strong>
                  <br />
                  <a href="mailto:#">thebrowzthreading@gmail.com</a>
                </address>
              </Col>
            </Row>
            <Row>
              <Col md={4} className="embed-responsive embed-responsive-1by1"> 
                  <iframe
                    title="inquiry-form"
                    className="embed-responsive-item"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSdzm3jEts_qSXbyq5ABvKAvMeYjdIhKaX_XhvRx9GD6_aWnjQ/viewform?embedded=true"
                    height="900px"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                  >
                    Loading…
                  </iframe>
              </Col>

              <Col md={8} className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="googleMap"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.800311863267!2d-71.12189318383555!3d42.389400241239755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37717ea35f4f7%3A0x73c0c8b7a7d8001c!2sThe%20Browz%20Threading!5e0!3m2!1sen!2sus!4v1585183392874!5m2!1sen!2sus"
                  allowFullScreen
                ></iframe>
              </Col>
            </Row>
          </Container>
        </section>
      </ScrollAnimation>
    );
  }
}

export default Contact;
