import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";

import { Navbar, Nav } from "react-bootstrap";

class NavBar extends Component {
  render() {
    return (
      <Container fluid>
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
          <Navbar.Brand href="/">
            <Image
              className="d-block w-20 img-fluid"
              src="images/threadGallery/logos/browzThreadCropLogo.jpg"
              alt="Natural Beauty"
              height="20"
              width="40"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Link to="/about" className="nav-link">
                About
              </Link>
              <Link to="/services" className="nav-link">
                Services
              </Link>
              {/* <Link to="/appointment" className="nav-link">
                Appointment
              </Link> */}
              <Link to="/hours" className="nav-link">
                Hours
              </Link>
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
              <Link to="/gallery" className="nav-link">
                Gallery
              </Link>
            </Nav>
            <a
              href="https://www.clover.com/online-ordering/the-browz-threading-cambridge/giftcard"
              target="_new"
              className="gift-cards"
            >
              Purchase Gift Cards
            </a>
            &nbsp;&nbsp;
            <Navbar.Text className="navbar-text">
              1923 Massachusetts Ave, Cambridge MA 02140 | 617-945-9214
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}

export default NavBar;
